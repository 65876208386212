// IntiaroAnalyticsManager.instance.sendEvent("attributeClicked", { InstanceId: this.data.id, InstanceType: this.instanceType, ProductConfigurationId: this.data.productId, CustomerId: this.data.customerId, AttributeId: sectionId, Mode: "open" });

var IntiaroAnalyticsManager = class IntiaroAnalyticsManager {

    constructor(settings) {
        if (!!IntiaroAnalyticsManager.instance) {
            return IntiaroAnalyticsManager.instance;
        }

        IntiaroAnalyticsManager.instance = this;

        this.instanceType = "analytics_manager";
        this.sendingStatus = "ready";
        this.intiaroAnalyticsPrefix = "IntiaroAnalytics_";
        this.browserSessionName = "IntiaroBrowserSession";
        this.deviceSessionName = "IntiaroDeviceSession";
        this.intiaroEventsLimit = 100;
        this.intiaroAnalyticsInterval = 5000;

        this.initialize(settings);
        this.eventsSessionObject = new AnalyticsSessionObject();

        return this;
    }

    initialize(settings)
    {
        this.configurationSessionUuid = generateIntiaroUuid();

        // default settings
        this.analyticsDomain = "https://analytics-backend.intiaro.com/analytics/";
        this.testBuild = "false";

        if (settings)
        {
            if (settings.analyticsDomain) {
                this.analyticsDomain = settings.analyticsDomain;
            }

            if (settings.testBuild !== undefined) {
                this.testBuild = settings.testBuild;
            }
        }

        // if (typeof intiaroConfig !== 'undefined')
        // {
        //     if(!!intiaroConfig.analyticsDomain)
        //     {
        //         this.analyticsDomain = intiaroConfig.analyticsDomain;
        //     }

        //     if (intiaroConfig.testBuild !=undefined)
        //     {
        //         this.testBuild = "true";
        //     }
        // }

        this.sendSavedSessions();

        setInterval(() => {
            this.sendSavedSessions();
            this.sendCurrentSessionSavedEvents();
        } , this.intiaroAnalyticsInterval);
    }


    sendEvent(eventName, eventParameters= {}, sendImmediately= false)
    {
        console.log("EVENT: " + eventName + " params: " + JSON.stringify(eventParameters));
        let eventUuid = generateIntiaroUuid();
        let eventInstance = new AnalyticsEventInstance(eventUuid, eventName, eventParameters);

        this.eventsSessionObject.events[eventUuid] = eventInstance;

        this.saveCurrentSession();

        if(sendImmediately)
        {
            this.sendCurrentSessionSavedEvents();
        }
        else if(eventName!="eventsSendFailed")
        {
            this.trySendSavedEvents();
        }
    }

    saveCurrentSession()
    {
        let serializedEventObject = JSON.stringify(this.eventsSessionObject);
        this.saveEvent(this.configurationSessionUuid, serializedEventObject);
    }

    saveEvent(uuid, serializedEventObject)
    {
        try{
            let key = this.intiaroAnalyticsPrefix + uuid;
            localStorage.setItem(key, serializedEventObject);
        }
        catch{
            console.error("unable to save analytics event");
        }
    }

    trySendSavedEvents()
    {
        if (Object.keys(this.eventsSessionObject.events).length>this.intiaroEventsLimit)
        {
            this.sendCurrentSessionSavedEvents();
        }
    }

    sendSavedSessions()
    {
        let savedSessionsCount = this.getSavedSessionsNumber();
        if (savedSessionsCount <=1) // <=1 because we ignore current session
        {
            return;
        }

        this.sessionsDictionary = {};
        for (let i = 0; i < localStorage.length; i++)
        {
            let key = localStorage.key(i);

            if (key.indexOf(this.intiaroAnalyticsPrefix)>-1)
            {
                let value = localStorage.getItem(key);
                key = key.replace(this.intiaroAnalyticsPrefix,"");
                if (key != this.configurationSessionUuid)
                {
                    // Don't try to send current session data
                    this.sessionsDictionary[key]=value;
                }
            }
        }

        for (let key in this.sessionsDictionary) {
            if (this.sessionsDictionary.hasOwnProperty(key)) {

                let sessionObj = JSON.parse( this.sessionsDictionary[key] );
                sessionObj.events = this.convertDictionaryToList(sessionObj.events);
                this.sessionsDictionary[key] = JSON.stringify(sessionObj)
                this.sendSingleSession(key, this.sessionsDictionary[key]);
            }
        }
    }

    convertDictionaryToList(dictionary)
    {
        let list=[];
        for (var key in dictionary) {
            if (dictionary.hasOwnProperty(key)) {
                list.push( dictionary[key] );
            }
        }
        return list;
    }

    sendSingleSession(key, serializedSessionObject)
    {
        $intiaro.ajax({
                url: this.analyticsDomain,
                data: serializedSessionObject,
                contentType:"application/json; charset=utf-8",
                dataType:"json",
                type:"POST"})
        .fail(
            function(error){
                this.sendEvent("eventsSendFailed", {InstanceType: this.instanceType, ResponseCode : error.status});
                console.log(error);
            }.bind(this))
        .done(
            function(){
                    localStorage.removeItem(this.intiaroAnalyticsPrefix+key);
            }.bind(this));
    }

    sendCurrentSessionSavedEvents()
    {
        if (Object.keys(this.eventsSessionObject.events).length <=0 || this.sendingStatus != "ready")
        {
            return;
        }

        this.sendingStatus = "sending";

        this.eventsSessionObjectCopy = $intiaro.extend({}, this.eventsSessionObject);
        this.eventsSessionObjectCopy.events = this.convertDictionaryToList(this.eventsSessionObjectCopy.events);
        let serializedSession = JSON.stringify(this.eventsSessionObjectCopy);

        $intiaro.ajax({
                url: this.analyticsDomain,
                data: serializedSession,
                contentType:"application/json; charset=utf-8",
                dataType:"json",
                type:"POST"})
        .fail(
            function(error){
                this.sendEvent("eventsSendFailed", {InstanceType: this.instanceType, ResponseCode : error.status});
                console.log(error);
            }.bind(this))
        .done(
            function(){
                //Delete sent events from current session object and save updated object to local storage
                for (let i = 0; i < this.eventsSessionObjectCopy.events.length; i++)
                {

                    let key = this.eventsSessionObjectCopy.events[i].uuid;
                    delete this.eventsSessionObject.events[key];
                }
                this.saveCurrentSession();

            }.bind(this))
            .always(
                function(){
                    this.sendingStatus = "ready";
                }.bind(this));
    }

    getSavedSessionsNumber()
    {
        let count = 0;

        for (let i = 0; i < localStorage.length; i++)
        {
            let key = localStorage.key(i);

            if (key.indexOf(this.intiaroAnalyticsPrefix)>-1)
            {
                count++;
            }
        }
        return count;
    }

    getCurrentSessionUuid()
    {
        return this.eventsSessionObject.header.session_uuid;
    }
};

var AnalyticsSessionObject = class AnalyticsSessionObject {
    constructor(){
        this.header = new AnalyticsEventHeader();
        this.events = {};
    }
};

var AnalyticsEventHeader = class AnalyticsEventHeader
{
    constructor()
    {
        this.session_uuid = this.getCookie(IntiaroAnalyticsManager.instance.browserSessionName);
        if(this.session_uuid==null)
        {
            this.session_uuid = generateIntiaroUuid();
            this.createCookie(IntiaroAnalyticsManager.instance.browserSessionName, this.session_uuid, 0);
        }

        if(IntiaroAnalyticsManager.instance.testBuild==true) {
            this.test_analytics = "true";
        } else {
            this.test_analytics = "false";
        }

        this.device = {
            udid : this.getCookie(IntiaroAnalyticsManager.instance.deviceSessionName)
        }

        if(this.device.udid==null)
        {
            this.device.udid = generateIntiaroUuid();
            this.createCookie(IntiaroAnalyticsManager.instance.deviceSessionName, this.device.udid, 10000);
        }
        this.app = new AnalyticsAppData();
    }

    createCookie(name, value, days) {
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            var expires = "; expires=" + date.toGMTString();
        }
        else var expires = "";

        document.cookie = name + "=" + value + expires + "; path=/";
    }

    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    deleteCookie(name) {
        this.createCookie(name, "", -1);
    }
}

class AnalyticsAppData
{
    constructor()
    {
        this.type = IntiaroSystemInfo.instance.appName;
        // this.device = IntiaroSystemInfo.instance.device;
        // this.os =  IntiaroSystemInfo.instance.operatingSystem;
        // this.browser = IntiaroSystemInfo.instance.browser;
    }
}

var AnalyticsEventInstance = class AnalyticsEventInstance
{
    constructor(uuid, name, params)
    {
        this.uuid = uuid;
        this.timestamp = Date.now();
        this.name = name;

        this.data = new AnalyticsEvenData(params);
        this.context = new AnalyticsEventContext(params)
    }

};

var AnalyticsEventContext = class AnalyticsEventContext {
    constructor(params)
    {
        this.configuration_session_uuid = IntiaroAnalyticsManager.instance.configurationSessionUuid;

        if (!!params)
        {
            if(!!params.InstanceId)
            {
                this.instance_id = params.InstanceId;
            }

            if(!!params.InstanceType)
            {
                this.instance_type = params.InstanceType;
            }

            if(!!params.CustomerId)
            {
                this.customer_id = params.CustomerId;
            }

            if(!!params.ProductConfigurationId)
            {
                this.product_configuration_id = params.ProductConfigurationId;
            }

            this.window_resolution = { resolution_x : $intiaro( window ).width(), resolution_y: $intiaro( window ).height()}
        }
    }
};

var AnalyticsEvenData = class AnalyticsEvenData
{
    constructor(params) {
      for (const [key, value] of Object.entries(params)) {
        this[key] = value;
      }
    }
};

var generateIntiaroUuid = function generateIntiaroUuid()
{
    var dt = Date.now();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16).toUpperCase();
    });
    return uuid;
};
