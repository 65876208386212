(function () {
    'use strict';
  
    if (!Array.isArray) {
      Array.isArray = function (arg) {
        return Object.prototype.toString.call(arg) === '[object Array]';
      };
    }
  
    let queryParams = {
  
      getAll: function() {
        let query = window.location.search;
        let obj = {};
  
        if (query === '') return obj;
  
        query = query.slice(1);
        query = query.split('&');

        query.map(function (part) {
          let key;
          let value;
  
          part = part.split('=');
          key = part[0];
          value = part[1];
  
          if (!obj[key]) {
            obj[key] = value;
          } else {

            if (!Array.isArray(obj[key])) {
              obj[key] = [obj[key]];
            }

            obj[key].push(value);
          }
        });

        return obj;
      }
    };
  
    if (window) {
      if (!window.queryParams) {
        window.queryParams = queryParams;
      } else {
        throw new Error('Error: window.queryParams already set.');
      }
    }
  })();


  var db3dUTMSaver = (function() {
    'use strict';

    let supportedParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

    function _filterUTMParams(queryParamsObj) {
      let utmParams = {};

      supportedParams.forEach(function (item, index) {
        if (queryParamsObj[item]) {
          utmParams[item] = queryParamsObj[item];
        }
      });

      return utmParams;
    }

    function _saveToCookies(utmParamsObj) {
      let cookieName = 'db3d_utms'
      let date = new Date();
      let daysToExpire = 1000;
      let cookieValue = JSON.stringify(utmParamsObj);

      date.setTime(date.getTime() + (daysToExpire*24*60*60*1000));
      var expires = "expires="+ date.toUTCString();
      document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
    }

    function saveAllUtmParams() {
      let queryParamsObj = queryParams.getAll();
      let utmParamsObj = _filterUTMParams(queryParamsObj);
      // Only save cookie if any utm keys were actually present
      // otherwise you may overwrite utms saved by another script or coming from another source
      if (Object.keys(utmParamsObj).length > 0 ) {
        _saveToCookies(utmParamsObj);
      }
    }

  return {
    saveAllUtmParams: saveAllUtmParams
  }

}());

db3dUTMSaver.saveAllUtmParams();